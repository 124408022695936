/** @format */

import axios, { Method, ResponseType } from 'axios';
// @ts-ignore
import { ManywattClient } from './services/index.ts';

export const url = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const createUrl = (endpoint: string) =>
  `${url}${!url.endsWith('/') ? '/' : ''}${
    endpoint.startsWith('/') ? endpoint.slice(1) : endpoint
  }`;

const isAbsolute = (endpoint: string) => /^https?\/\//.test(endpoint);

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = url;

/**
 * Send a request to API
 * @param method
 * @param endpoint
 * @param params
 * @param data
 * @param headers
 * @param responseType
 * @returns {AxiosPromise}
 */
const request = (
  method: Method,
  endpoint: string,
  params = {},
  data = {},
  headers = {},
  responseType: ResponseType = 'json',
) =>
  axios
    .request({
      method,
      url: endpoint,
      params,
      data,
      headers,
      responseType,
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.detail ===
          "Informations d'authentification non fournies."
      ) {
        if (endpoint !== '/whoami/') {
          window.location.href = '/login/';
        }
      }
      throw error;
    });

const requests = {
  /**
   * Send a GET request to API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  get: (
    endpoint: string,
    params = {},
    headers = {},
    responseType: ResponseType = 'json',
  ) => request('get', endpoint, params, undefined, headers, responseType),
  /**
   * Send a POST request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @param responseType
   * @returns {AxiosPromise}
   */
  post: (
    endpoint: string,
    params = {},
    data = {},
    headers = {},
    responseType: ResponseType = 'json',
  ) => request('post', endpoint, params, data, headers, responseType),
  /**
   * Send a PUT request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @returns {AxiosPromise}
   */
  put: (endpoint: string, params = {}, data = {}, headers = {}) =>
    request('put', endpoint, params, data, headers),
  /**
   * Send a PATCH request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @returns {AxiosPromise}
   */
  patch: (endpoint: string, params = {}, data = {}, headers = {}) =>
    request('patch', endpoint, params, data, headers),
  /**
   * Send a DELETE request to API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  delete: (endpoint: string, params = {}, headers = {}) =>
    request('delete', endpoint, params, undefined, headers),
  /**
   * Send a GET request to download from API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  download: (
    endpoint: string,
    params = {},
    headers = {
      'Content-Type': 'application/json',
      // Accept: 'application/pdf',
    },
  ) => request('get', endpoint, params, {}, headers, 'arraybuffer'),
  /**
   * Send a POST request to download from API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @returns {AxiosPromise}
   */
  downloadPost: (
    endpoint: string,
    params = {},
    data = {},
    headers = {
      'Content-Type': 'application/json',
      // Accept: 'application/pdf',
    },
  ) => request('post', endpoint, params, data, headers, 'arraybuffer'),
};

export const api = requests;

/* const localApi = new OpenAPIClientAxios({ definition: 'http://localhost:8000/api/schema/' });
localApi.init();

export const useApi = () => localApi;

export const axiosInstance = axios.create({
  baseURL: url,
}); */

export const manywattClient = new ManywattClient({
  BASE: url,
  // VERSION: '1.0.0',
  WITH_CREDENTIALS: true,
  CREDENTIALS: 'include',
  // TOKEN: undefined,
  // USERNAME: undefined,
  // PASSWORD: undefined,
  // HEADERS: undefined,
  // ENCODE_PATH: undefined,
});
